import React, { useState, useEffect } from "react";
import TireCard from "../productCard/tireCard/tireCard";
import * as StyledComponents from "./choiceTires.module.scss";

// markup
export const ChoiceTires = (tires) => {
  const [TIRES, setTIRES] = useState([{}]);

  useEffect(() => {
    //console.log("THIS TIRES");
    //console.log(tires.tires);
    setTIRES(tires.tires);
    //console.log(TIRES);
  }, [tires]);

  return (
    <div className={StyledComponents.flex}>
      {TIRES.map((item) => {
        if (item.sku !== undefined) {
          return (
            <>
              <TireCard
                image={item.image}
                sku={item.sku}
                brand={item.brand}
                model={item.model}
                price={item.price}
                diam={item.diameter}
                aspect={item.aspectRatio}
                width={item.sectionWidth}
                season={item.season}
                ply={item.ply}
                lt={item.lt}
                qwinter={item.qwinter}
              />
            </>
          );
        }
        return <></>;
      })}
    </div>
  );
};
