import React from "react";
import AddToCartButtonCard from "../cart/addToCartButton/addToCartButton";
import * as StyledComponets from "./wheelPage.module.scss";
import snow from "../../icons/snow.svg";

// markup
const WheelItem = ({
  image,
  sku,
  brand,
  name,
  stylenum,
  price,
  diam,
  width,
  season,
  color,
  finish,
  offset,
  pcd1,
  pcd2,
  montreal,
  toronto,
  vancouver,
  losangeles,
  dallas,
  suppplier,
  aggresive,
}) => {
  if (stylenum === null) {
    stylenum = "";
  }
  if (color === null) {
    color = "";
  }
  var xoff = "";
  if (offset > 0) {
    xoff = "+";
  }
  function isWinterProof() {
    if (season === "summer") {
      return <div className={StyledComponets.snow}></div>;
    } else {
      return (
        <img className={StyledComponets.snow} src={snow} alt="winter proof" />
      );
    }
  }

  //returns pcd2 if exists
  function pcd2Disp() {
    if (pcd2 === null || pcd2 === "") {
      return "";
    } else {
      return " | " + pcd2;
    }
  }

  //var to change the currency formats
  var formatter = new Intl.NumberFormat("fr-CA", {
    style: "currency",
    currency: "CAD",
  });

  function isAggresive() {
    console.log(aggresive);
    if (aggresive === true) {
      return (
        <p>
          <span> &#x26A0;</span> L'installation des jantes ou des pneus
          agressifs pourrait nécessiter des modifications du véhicule et
          pourrait être illégale pour l'utilisation sur la route dans certaines
          juridictions. Veuillez vérifier avec vos règlements locaux.
        </p>
      );
    }
  }

  return (
    <article className={StyledComponets.article}>
      <img
        className={StyledComponets.magimage}
        loading="lazy"
        src={image}
        alt={
          brand +
          " " +
          name +
          " " +
          diam +
          "x" +
          width +
          " " +
          color +
          " " +
          finish
        }
      />
      <div className={StyledComponets.card}>
        <div className={StyledComponets.snowContainer}>{isWinterProof()}</div>
        <h3>{brand}</h3>
        <h4>{name + " " + stylenum}</h4>
        <h5>
          {diam +
            "x" +
            width +
            " | " +
            xoff +
            offset +
            " | " +
            pcd1 +
            pcd2Disp()}
        </h5>
        <h5>{sku}</h5>
        <h5>{color + finish}</h5>

        <div className={StyledComponets.price}>
          <h2>{formatter.format(price)}</h2>
          <p>&nbsp;ch.</p>
        </div>

        <AddToCartButtonCard
          ximage={image}
          xsku={sku}
          xbrand={brand}
          xname={name}
          xstylenum={stylenum}
          xprice={price}
          xdiam={diam}
          xwidth={width}
          xseason={season}
          xcolor={color}
          xfinish={finish}
          xoffset={offset}
          xpcd1={pcd1}
          xpcd2={pcd2}
          xsupplier={suppplier}
        />
      </div>

      <div className={StyledComponets.flex}>
        <div className={StyledComponets.inventory}>
          <h2>Inventaire</h2>
          <div>
            <p>MONTREAL : {montreal}</p>
            <p>TORONTO : {toronto}</p>
            <p>VANCOUVER : {vancouver}</p>
          </div>
          <div className={StyledComponets.delais}>
            <p>Délais de livraison : 2-5 jours ouvrables</p>
          </div>
          <div>
            <p>LOS ANGELES : {losangeles}</p>
            <p>DALLAS : {dallas} </p>
          </div>
          <div className={StyledComponets.delais}>
            <p>Délais de livraison : 2-3 semaines</p>
          </div>
        </div>
        <div className={StyledComponets.lifted}>{isAggresive()}</div>
      </div>
    </article>
  );
};

export default WheelItem;
