export async function getTireFitment(diam, width, offset) {
  if (localStorage.getItem("COvehicle") !== null) {
    let x = localStorage.getItem("COvehicle");
    x = JSON.parse(x);
    let minW = x[0].minusWheels;
    let plusW = x[0].plusWheels;
    //get both fittments
    console.log(minW);
    console.log(plusW);
    //serach on min wheels
    for (let i = 0; i < minW.length; i++) {
      let d = minW[i].diameter;
      let w = minW[i].width;
      let omin = minW[i].offmin;
      let omax = minW[i].offmax;
      if (diam === d && width === w && offset <= omax && offset >= omin) {
        return minW[i].tires;
      }
    }
    //search on plus wheels
    for (let i = 0; i < plusW.length; i++) {
      let d = plusW[i].diameter;
      let w = plusW[i].width;
      let omin = plusW[i].offmin;
      let omax = plusW[i].offmax;
      if (diam === d && width === w && offset <= omax && offset >= omin) {
        return plusW[i].tires;
      }
    }
    //return empty if not find
    return [];
  } else {
    return [];
  }
}
