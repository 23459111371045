import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { PageLayout } from "../components/layout/layout";
import WheelItem from "../components/wheelPage/wheelPage";
import Container from "../components/container/container";
import { ChoiceTires } from "../components/wheelPage/choiceTires";
import { getTireFitment } from "../components/wheelPage/functions";

// Import querry string library
var qs = require("qs");

// markup
const WheelPage = () => {
  const [IMAGE, setIMAGE] = useState();
  const [SKU, setSKU] = useState();
  const [BRAND, setBRAND] = useState("");
  const [NAME, setNAME] = useState("");
  const [STYLENUM, setSTYLENUM] = useState();
  const [PRICE, setPRICE] = useState();
  const [DIAM, setDIAM] = useState();
  const [WIDTH, setWIDTH] = useState();
  const [SEASON, setSEASON] = useState();
  const [COLOR, setCOLOR] = useState();
  const [FINISH, setFINISH] = useState();
  const [OFFSET, setOFFSET] = useState();
  const [PCD1, setPCD1] = useState();
  const [PCD2, setPCD2] = useState();
  const [MONTREAL, setMONTREAL] = useState();
  const [TORONTO, setTORONTO] = useState();
  const [VANCOUVER, setVANCOUVER] = useState();
  const [LOSANGELES, setLOSANGELES] = useState();
  const [DALLAS, setDALLAS] = useState();
  const [TIRES, setTIRES] = useState([{}]);
  const [SUPPLIER, setSUPPLIER] = useState();
  const [AGRESIVE, setAGRESIVE] = useState(false);

  //fetch tire
  async function searchTire(givenSize) {
    let width;
    let hight;
    let diameter;
    if (givenSize.includes("/") === true) {
      width = givenSize.split("/")[0];
      let x = givenSize.split("/")[1];
      hight = x.split("R")[0];
      diameter = x.split("R")[1];
    } else {
      width = givenSize.split("x")[0];
      let x = givenSize.split("x")[1];
      hight = x.split("R")[0];
      diameter = x.split("R")[1];
    }
    const query = qs.stringify({
      _where: [
        { sectionWidth: JSON.stringify(parseFloat(width)) },
        { aspectRatio: JSON.stringify(parseFloat(hight)) },
        { diameter: JSON.stringify(parseFloat(diameter)) },
        /*AND*/
        {
          _or: [{ montreal_gte: 4 }, /*OR*/ { toronto_gte: 4 }],
        },
      ],
    });
    await fetch(`https://www.wheels-api.com/tires?${query}`)
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("Tires Result raw");
          //console.log(result);
          setTIRES(result);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    function getItemInfos(givenSKU) {
      let fetchURL = "https://www.wheels-api.com/wheels?sku_eq=" + givenSKU;
      fetch(fetchURL)
        .then((res) => res.json())
        .then(
          (result) => {
            setIMAGE(result[0].photo);
            setSKU(result[0].sku);
            setBRAND(result[0].brand);
            setNAME(result[0].name);
            setSTYLENUM(result[0].stylenum);
            setPRICE(result[0].price);
            setDIAM(result[0].diameter);
            setWIDTH(result[0].width);
            setSEASON(result[0].season);
            setCOLOR(result[0].color);
            setFINISH(result[0].finish);
            setOFFSET(result[0].offset);
            setPCD1(result[0].pcd1);
            setPCD2(result[0].pcd2);
            setMONTREAL(result[0].montreal);
            setTORONTO(result[0].toronto);
            setVANCOUVER(result[0].vancouver);
            setDALLAS(result[0].dallas);
            let x =
              result[0].losangeles1011 +
              result[0].losangeles1031 +
              result[0].losangeles1085;

            setLOSANGELES(x);
            setSUPPLIER(result[0].supplier);
            // console.log(result);

            //get tires fitting on the wheel
            getResult(result[0].diameter, result[0].width, result[0].offset);
          },
          (error) => {
            console.log(error);
          }
        );
    }

    //start the fetching fot tires
    async function getResult(d, w, o) {
      let result = await getTireFitment(d, w, o);
      // console.log("tireFitments");
      // console.log(result);

      //dont continue if no tesults are shown
      if (result.length === 0) {
        return;
      }
      searchTire(result[0]);
    }

    //get the value of the SKU on the URL
    function getURLparameters() {
      let url = new URL(document.location.href);
      url.searchParams.sort();
      let keys = url.searchParams.keys();
      for (let key of keys) {
        let val = url.searchParams.get(key);
        val = val.replace(" ", "%2B");
        if (key === "sku") {
          getItemInfos(val);
        }
        if (key === "ag") {
          setAGRESIVE(true);
        }
      }
    }
    getURLparameters();
  }, []);

  return (
    <PageLayout>
      <Helmet>
        <title>
          {BRAND} {NAME}
        </title>
      </Helmet>
      <Container>
        <WheelItem
          image={IMAGE}
          sku={SKU}
          brand={BRAND}
          name={NAME}
          stylenum={STYLENUM}
          price={PRICE}
          diam={DIAM}
          width={WIDTH}
          season={SEASON}
          color={COLOR}
          finish={FINISH}
          offset={OFFSET}
          pcd1={PCD1}
          pcd2={PCD2}
          montreal={MONTREAL}
          toronto={TORONTO}
          vancouver={VANCOUVER}
          losangeles={LOSANGELES}
          dallas={DALLAS}
          suppplier={SUPPLIER}
          aggresive={AGRESIVE}
        />
        <ChoiceTires tires={TIRES} />
      </Container>
    </PageLayout>
  );
};
export default WheelPage;
