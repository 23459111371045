import React, { useState } from "react";
import { addWheelToCart } from "../cartFunctions";
import Button from "@mui/material/Button";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import * as StyledComponents from "./addToCartButton.module.scss";

//redux
import { useSelector, useDispatch } from "react-redux";
import allActions from "../../redux/actions";

// markup
const AddToCartButtonCard = ({
  ximage,
  xsku,
  xbrand,
  xname,
  xstylenum,
  xprice,
  xdiam,
  xwidth,
  xseason,
  xcolor,
  xfinish,
  xoffset,
  xpcd1,
  xpcd2,
  xsupplier,
}) => {
  //redux
  const NewCartContent = useSelector((state) => state.newCart);
  const dispatch = useDispatch();

  //default value for items count
  const [Count, setCount] = useState(4);

  //change the count
  function changeCount(params) {
    let newCount = Count;
    if (params === "-" && Count !== 1) {
      newCount--;
      setCount(newCount);
    }
    if (params === "+") {
      newCount++;
      setCount(newCount);
    }
  }

  //add product to redux cart
  function addProduct() {
    //object of new product to add
    let productObject = {
      image: ximage,
      sku: xsku,
      brand: xbrand,
      name: xname,
      stylenum: xstylenum,
      price: xprice,
      diam: xdiam,
      width: xwidth,
      season: xseason,
      color: xcolor,
      finish: xfinish,
      offset: xoffset,
      pcd1: xpcd1,
      pcd2: xpcd2,
      supplier: xsupplier,
      total: Count,
    };

    // perform operations and add to LocalStorage
    let newCart = addWheelToCart(NewCartContent, productObject);

    //add product to redux cart
    dispatch(allActions.cart.setNewCart(newCart));
  }

  return (
    <div className={StyledComponents.button}>
      <button onClick={() => changeCount("-")}>
        <h6>-</h6>
      </button>
      <h6>{Count}</h6>
      <button onClick={() => changeCount("+")}>
        <h6>+</h6>
      </button>
      <Button
        onClick={() => addProduct()}
        className={StyledComponents.ajouter}
        endIcon={<ShoppingCartIcon />}
        variant="contained"
        fullWidth={true}
        sx={{
          backgroundColor: "var(--darkgreen)",
          borderRadius: "0",
          [`&:hover, &.Mui-focusVisible`]: {
            backgroundColor: "var(--darkgreen)",
          },
        }}
      >
        <p>Ajouter au panier</p>
      </Button>
    </div>
  );
};

export default AddToCartButtonCard;
