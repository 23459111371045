// extracted by mini-css-extract-plugin
export var article = "wheelPage-module--article--cmfEv";
export var magimage = "wheelPage-module--magimage--f8T9o";
export var card = "wheelPage-module--card--IHgJm";
export var snowContainer = "wheelPage-module--snowContainer--5bG4S";
export var snow = "wheelPage-module--snow--NN11W";
export var transparent = "wheelPage-module--transparent--GnPEv";
export var price = "wheelPage-module--price--S8ps+";
export var flex = "wheelPage-module--flex--2UK1Y";
export var inventory = "wheelPage-module--inventory--XUtx1";
export var delais = "wheelPage-module--delais--FRYyf";
export var lifted = "wheelPage-module--lifted--gJ4Up";